/** Story Teller Section */

import Swiper, { EffectFade, Navigation, Pagination } from "swiper";

export default {
    init() {

        if (document.querySelector('.swiper.swiper-story-teller')) {
            Array.from(document.querySelectorAll('.swiper.swiper-story-teller'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        modules: [EffectFade, Navigation, Pagination],
                        loop: true,
                        effect: 'fade',
                        fadeEffect: {
                            crossFade: true
                        },
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'bullets',
                            clickable: true
                        }
                    })
                });
        }
    }
}
