/** Features Image Section */

import Swiper, { Navigation, Grid } from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper.swiper-feature-items')) {
            Array.from(document.querySelectorAll('.swiper.swiper-feature-items'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        modules: [Navigation, Grid],
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                        grabCursor: true,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        grid: {
                            fill: 'row',
                            rows: 2,
                        },
                        breakpoints: {
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 45,
                                grabCursor: false,
                                grid: {
                                    fill: 'row',
                                    rows: 4,
                                },
                            }
                        }
                    });
                });
        }
    }
}
