/** Content Cards Slider Section */

import Swiper, { Navigation } from "swiper";

export default {
    init() {

        if (document.querySelector('.swiper.swiper-cards')) {
            Array.from(document.querySelectorAll('.swiper.swiper-cards'))
                .forEach(el => {

                    el.swiper = new Swiper(el, {
                        modules: [Navigation],
                        slidesPerView: 1.3,
                        spaceBetween: 35,
                        grabCursor: true,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        breakpoints: {
                            600: {
                                slidesPerView: 1.5,
                            },
                            768: {
                                slidesPerView: 2.3,
                            },
                            992: {
                            },
                            1200: {
                                slidesPerView: 2.5,
                            }
                        }
                    });
                });
        }

        window.addEventListener('resize', this.updateCardTranslation);
        window.addEventListener('DOMContentLoaded', this.updateCardTranslation);

        if (document.readyState === 'complete') {
            this.updateCardTranslation();
        }
    },
    updateCardTranslation() {
        Array.from(document.querySelectorAll('.card-slide'))
            .forEach(card => {
                let cardContent = card.querySelector('.card__content');

                if (cardContent) {
                    let cardTitle = card.querySelector('.card__title');
                    let offset = cardTitle ? cardTitle.offsetHeight + 46 : 46;

                    cardContent.style.transform = "translateY(calc(100% - " + offset + "px))";
                }
            });
    }
}
