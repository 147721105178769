/** Logos Section */

import Swiper, { Autoplay } from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper-logos')) {
            Array.from(document.querySelectorAll('.swiper-logos'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        modules: [Autoplay],
                        allowTouchMove: false,
                        autoplay: {
                            delay: 1,
                            disableOnInteraction: false,
                            reverseDirection: el.dataset.direction === 'right'
                        },
                        speed: 6000,
                        slidesPerView: 'auto',
                        loop: true,
                        spaceBetween: 50,
                        slidesOffsetBefore: el.dataset.direction === 'right' ? 50 : 0
                    });
                });
        }
    }
}
