/** Navigation */

export default {
    init() {
        this.initSubMenuToggle();
        this.initToggleMenu();
    },
    initSubMenuToggle() {
        const navElement = document.getElementById('nav-primary');
        const menus = document.getElementById('menus');

        if (!menus) {
            return;
        }

        const menuItemsWithChildren = menus.querySelectorAll('.menu-item-has-children');

        for (let i = 0; i < menuItemsWithChildren.length; i++) {
            // When clicked toggle active class, and remove active class from other items
            menuItemsWithChildren[i].addEventListener('click', e => {
                if (e.target.tagName != 'A') {
                    let isActive = e.currentTarget.classList.contains('open');

                    menuItemsWithChildren.forEach(el => el.classList.toggle('open', false));
                    e.currentTarget.classList.toggle('open', !isActive);
                }
            });
        }

        // When mouse leaves nav element close dropdown
        navElement.addEventListener('mouseleave', e => {
            menuItemsWithChildren.forEach(el => el.classList.toggle('open', false));
        });
    },
    /**
     * Toggle event for mobile navigation
     */
    initToggleMenu() {
        const nav = document.getElementById('nav-primary');
        const menus = document.getElementById('menus');
        const toggleMenuButton = Array.from(document.querySelectorAll('.menu-toggle'));

        if (menus && toggleMenuButton) {
            toggleMenuButton.forEach(el => {
                el.addEventListener('click', e => {
                    e.preventDefault();

                    let res = menus.classList.toggle('open');
                    nav.classList.toggle('open');
                    document.body.classList.toggle('disable-scroll', res);
                });
            });
        }
    }
}
