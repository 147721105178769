/** Forms */

class BlogCategoryForm {

    constructor(element) {

        if (!(element instanceof HTMLElement)) {
            throw new TypeError('Invalid element');
        }

        this.form = element;

        if (this.form.querySelector('select')) {
            Array.from(this.form.querySelectorAll('select'))
                .forEach(select => select.addEventListener('change', this.handleSelectChange.bind(this.form)));
        }
    }

    handleSelectChange(event) {
        event.preventDefault();

        let url = this.action;
        let category = event.currentTarget.value;

        if (typeof category === 'undefined' || category === null) {
            return;
        }

        window.location = `${url}${category}`;
    }

}

export default {
    init() {
        if (document.querySelector('form.form-blog-category')) {
            Array.from(document.querySelectorAll('form.form-blog-category'))
                .forEach(form => form.handler = new BlogCategoryForm(form));
        }
    }
}
