/**
 * Accordion Slider Section
 */
import AccordionSlider from "./AccordionSlider";

export default {
    init() {
        if (document.querySelector('.section--accordion-slider')) {
            Array.from(document.querySelectorAll('.section--accordion-slider'))
                .forEach(el => {
                    new AccordionSlider(el);
                });
        }
    }
}