/** Stepped Features Section */

import Swiper from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper.swiper-stepped-features')) {
            Array.from(document.querySelectorAll('.swiper.swiper-stepped-features'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        slidesPerView: 1.2,
                        spaceBetween: 40,
                        breakpoints: {
                            768: {
                                slidesPerView: 'auto',
                                spaceBetween: 80,
                            }
                        }
                    });
                });
        }
    }
}
