/** globals: jQuery, wordpress_globals */

import "../sass/theme.scss";

import $ from 'jquery';
import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import navigation from './modules/navigation';
import forms from './modules/forms';
import accordionSliderSection from './modules/accordionSliderSection';
import contentCardsSliderSection from './modules/contentCardsSliderSection';
import featuresImageSection from "./modules/featuresImageSection";
import logosSection from "./modules/logosSection";
import steppedFeaturesSection from "./modules/steppedFeaturesSection";
import storyTellerSection from "./modules/storyTellerSection";
import testimonialsSliderSection from './modules/testimonialsSliderSection';

window.heron = {
    init: function() {

        this.helpers.initSvg();

        //Allow user to interrupt auto-scrolling
        $(document).bind('scroll mousedown wheel keyup', function(e) {
            if(e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
                $(document.scrollingElement).stop();
            }
        });

        // Components
        navigation.init();
        forms.init();

        // Sections
        accordionSliderSection.init();
        contentCardsSliderSection.init();
        featuresImageSection.init();
        logosSection.init();
        steppedFeaturesSection.init();
        storyTellerSection.init();
        testimonialsSliderSection.init();

        // Smooth scrolling for anchor links, jQuery used for cross browser support
        $(document).on('click', 'a[href^="#"]:not([href="#"]):not([href="#0"])', function (event) {
            event.preventDefault();

            let navElement = document.getElementById('nav-primary');
            let offset = navElement ? navElement.offsetHeight : 100;

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - offset
            }, 700);
        });

    },
    helpers: {
        requireAll: function(r) {
            r.keys().forEach(r);
        },
        initSvg: function (){
            svgxhr({
                filename: './wp-content/themes/class/dist/sprite.svg', //URL to fetch
            });
        }

    }

    // Prefer creating new modules when appropriate, rather than adding functions here
    // If you need something to be globally accessible, you can assign an imported module to this namespace
    // i.e. import Contact from './contact';
    //  window.namespace.Contact = Contact;
};

window.heron.init();


if(typeof NodeList.prototype.forEach !== 'function'){
    NodeList.prototype.forEach = Array.prototype.forEach;
}

// SVG Sprite Loader
window.heron.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));
