/** Testimonials Slider Section */

import Swiper, { Autoplay, Navigation } from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper-testimonials')) {
            Array.from(document.querySelectorAll('.swiper-testimonials'))
                .forEach(el => {

                    let slidesPerView = 1;
                    let numberOfSlides = Array.from(el.querySelectorAll('.swiper-slide')).length;

                    el.swiper = new Swiper(el, {
                        modules: [Autoplay, Navigation],
                        autoplay: {
                            delay: 10000,
                            disableOnInteraction: true,
                        },
                        speed: 600,
                        slidesPerView: 1.2,
                        spaceBetween: 30,
                        centeredSlides: true,
                        loop: numberOfSlides > slidesPerView * 2,
                        loopedSlides: 2,
                        grabCursor: true,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        breakpoints: {
                            768: {
                                spaceBetween: 40,
                                slidesPerView: slidesPerView,
                                loop: numberOfSlides > slidesPerView * 2,
                            }
                        }
                    });
                });
        }
    }
}
