/** Accordion Slider */

import Swiper, { EffectFade } from "swiper";
import Accordion from "./Accordion";

export default class AccordionSlider {

    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError('Invalid element');
        }

        this.section = element;
        this.swiperElement = this.section.querySelector('.swiper');
        this.accordionElement = this.section.querySelector('.accordion');

        this.initSwiper();
        this.initAccordion();
    }

    initSwiper() {
        this.swiper = new Swiper(this.swiperElement, {
            modules: [EffectFade],
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            allowTouchMove: false
        });
    }

    initAccordion() {
        this.accordion = new Accordion(this.accordionElement);
        this.accordionElement.addEventListener('accordion:changed', () => {
            this.swiper.slideTo([...this.accordion.items].indexOf(this.accordion.active));
        });
    }
}